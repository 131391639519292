export const getCurryingFile = ( files= [] ) => (key) => {
	for (const currentFile of files) {
		if (key === currentFile?.description) {
			return currentFile;
		}
	}
	return null;
};


export default getCurryingFile;
