const navigationHeader = [
	{
		to    : "/app/appraise/documentation",
		label : "Documentación",
	},
	{
		to    : "/app/appraise/request",
		label : "Solicitud de Avalúo",
	},
	{
		to    : "/app/appraise/geolocation",
		label : "Domicilio Geográfico",
	},
];

const navigationBody = [
	{
		title   : "Características Generales",
	    options : [
			{
				to    : "/app/appraise/section-1",
				label : "1.1 Antecedentes",
			},
			{
				to    : "/app/appraise/section-2",
				label : "1.4 Entorno",
			},
		],
	},
	{
		title   : "Características Particulares",
	    options : [
			{
				to    : "/app/appraise/section-3",
				label : "2.1 Terreno",
			},
			{
				to    : "/app/appraise/section-4",
				label : "2.2 Descripción General de las Construcciones",
			},
			{
				to    : "/app/appraise/section-5",
				label : "2.4 Elementos de Construcción",
			},
			{
				to    : "/app/appraise/section-infonavit",
				label : "INFONAVIT",
			},
		],
	},
	{
		title   : "Enfoques de Valuación",
	    options : [
			{
				to    : "/app/appraise/physical-approach",
				label : "Enfoque Físico",
			},
			{
				to    : "/app/appraise/business-approach",
				label : "Enfoque de Mercado",
			},
			{
				to    : "/app/appraise/income-capitalization-approach",
				label : "Enfoque de Capitalización de Rentas",
			},
		],
	},

];

const navigationFooter = [
	{
		title   : "Archivos",
	    options : [
			{
				to    : "/app/appraise/pictures",
				label : "Cargar Fotografías",
			},
		],
	},
	{
		title   : "App Infonavit",
	    options : [
			{
				to    : "/app/appraise/files-infonavit",
				label : "Obtener Fotos",
			},
		],
	},
	{
		title   : "Más",
	    options : [
			{
				to    : "/app/appraise/conclusion",
				label : "Conclusiones",
			},
			{
				to    : "/app/appraise/appraisal-cost",
				label : "Costo del Avalúo",
			},
			{
				to    : "/app/appraise/dtc",
				label : "Dictamen Técnico (DTC)",
			},
		],
	},
];

const apprasialNavigation = {
	navigationBody,
	navigationHeader,
	navigationFooter,
};

export default apprasialNavigation;
