import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	docs          : [],
	data          : {},
	page          : 1,
	totalPages    : 1,
	totalDocs     : 0,
	limit         : 16,
	pagingCounter : 0,

	modal : {
		open       : false,
		info       : {},
		userToEdit : "",
	},
};

const UsersSlice = createSlice({
	name     : "users",
	initialState,
	reducers : {
		getAllUsers : (state, action) =>  {
			return {
				...state,
				...action.payload,
			};
		},
		getUser : (state, action) =>  {
			return {
				...state,
				data : { ...action.payload.data },
			};
		},
		editUserDocs : (state, action) => {
			const userEdited = action.payload.data;
			const userIndex = state.docs.findIndex( user => user._id === userEdited._id );

			const newUsers = [ ...state.docs ];
			newUsers[userIndex] = { ...userEdited };

			return {
				...state,
				docs : [...newUsers],
			};
		},
		editUser : (state, action) => {
			return {
				...state,
				data : {
					...state.data,
					...action.payload.data,
				},
			};
		},
		editStatusUser : (state, action) => {
			return {
				...state,
				data : {
					...state.data,
					active : action.payload.data.active,
				},
			};
		},
		clearUser : (state) => {
			return {
				...state,
				data : {},
			};
		},
		openCreateUserModal : (state, actions) => {
			state.modal.open = true;
			state.modal.userToEdit = actions.payload;
		},
		setModalData : (state, actions) => {
			return {
				...state,
				modal : {
					...state.modal,
					info : { ...actions.payload.data },
				},
			};
		},
		closeCreateUserModal : (state) => {
			state.modal.open       = false;
			state.modal.info       = {};
			state.modal.userToEdit = "";
		},
	},
});

export default UsersSlice;
