const parseDMSHToDecimals = (dmsCoordinates) => {
	const { degrees, minutes, seconds, hemisphere } = dmsCoordinates;
	let decimals = Number(degrees) + (Number(minutes) / 60) +( Number(seconds) / 3600);
	console.log(dmsCoordinates, degrees, minutes, seconds, hemisphere);
	console.log(decimals);

	// Don't do anything for north or east
	if (hemisphere?.value === "south" || hemisphere?.value === "west") {
		decimals = decimals * -1;
	}
	return roundFixed(decimals);
};

export const roundFixed = (num) => {
	const parsedNum = parseFloat(num);
	return Number(parsedNum) ? parsedNum.toFixed(7) : 0;
};

export default parseDMSHToDecimals;
