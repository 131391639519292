
const getDMSCoordinates = (dd, longOrLat) => {
	const hemisphereIndex = isLong(longOrLat) ?
		getLongHemisphereIndex(dd) : getLatgHemisphereIndex(dd);

	const absDD = Math.abs(dd);
	const degrees = truncate(absDD);
	const minutes = truncate((absDD - degrees) * 60);
	const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);

	const dmsArray = [degrees, minutes, seconds, hemisphereIndex];
	return dmsArray;
};

const isLong = (long) => long === "long";

const getLongHemisphereIndex = (decimals) => {
	//the index values come from longDirectionOptions
	const index = (decimals < 0)  ? 0 : 1; // 0 = "west", 1 = "eatst"
	return index;
};

const getLatgHemisphereIndex = (decimals) => {
	//the index values come from latDirectionOptions
	const index = (decimals < 0)  ? 1 : 0; // 0 = "north", 1 = "south"
	return index;
};

const truncate = (n) => n > 0 ? Math.floor(n) : Math.ceil(n);

export default getDMSCoordinates;
