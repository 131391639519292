import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	filters : {
		page         : 1,
		search       : "",
		limit        : 16,
		perits       : null,
		date         : null,
		state        : null,
		municipality : "",
	},
};

const AppraisalSlice = createSlice({
	name     : "apprasial",
	initialState,
	reducers : {
		setFilters : (state, action) => void (state.filters = { ...state.filters, ...action.payload, page : 1 }),

		setSearchFilter : (state, action) => void(state.filters = { ...initialState.filters, search : action.payload }),

		setPageFilter : (state, action) => void(state.filters = { ...state.filters, page : action.payload }),

		clearFilters : (state) => void(state.filters = initialState.filters),
	},
});

export default AppraisalSlice;
