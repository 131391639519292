import PropTypes              from "prop-types";
import { isValidElementType } from "react-is";
import { Button }             from "reactstrap";
import { toast }              from "react-toastify";
import {
	X,
	AlertCircle,
	CheckCircle,
	MinusCircle,
} from "react-feather";

//Our Imports
import "./Toaster.scss";

const ToasterComponent = ({
	body,
	title,
	classNameSvg,
	icon : Icon,
}) => (
	<div id="ToasterComponent">
		<div className="customs-styles-toast">
			<div className="upper">
				<Icon size={16} className={classNameSvg} />
				<span className="title">
					{title}
				</span>
				<Button.Ripple
					color='transparent'
					className='closeToaster'
				>
					<X size={16}  />
				</Button.Ripple>
			</div>
			<div className="lower">
				<span className="text">
					{body}
				</span>
			</div>
		</div>
	</div>
);

ToasterComponent.propTypes = {
	icon : (props, propName) => {
		if (props[propName] && !isValidElementType(props[propName])) {
			return new Error(
				"Invalid prop 'icon' supplied to 'ToasterComponent': the prop is not a valid React component"
			);
		}
	},
	title : PropTypes.string.isRequired,
	body  : PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

const toaster = {
	info : props => toast.info(
		<ToasterComponent
			{...props}
			icon={props?.icon ? props.icon : AlertCircle}
			classNameSvg="info-icon"

		/>, {
			hideProgressBar : true,
			className       : "info",
			position        : toast.POSITION.TOP_RIGHT,
		}),
	success : props => toast.success(
		<ToasterComponent
			{...props}
			icon={props?.icon ? props.icon : CheckCircle}
			classNameSvg="success-icon"

		/>, {
			hideProgressBar : true,
			className       : "success",
			position        : toast.POSITION.TOP_RIGHT,
		}),
	warning : props => toast.warning(
		<ToasterComponent
			{...props}
			icon={props?.icon ? props.icon : AlertCircle}
			classNameSvg="warning-icon"

		/>, {
			hideProgressBar : true,
			className       : "warning",
			position        : toast.POSITION.TOP_RIGHT,
		}),
	error : props => toast.error(
		<ToasterComponent
			{...props}
			icon={props?.icon ? props.icon : MinusCircle}
			classNameSvg="error-icon"

		/>, {
			hideProgressBar : true,
			className       : "error",
			position        : toast.POSITION.TOP_RIGHT,
		}),
};

export default toaster;
