const reactSelectStyles = (error, size) => {

	const customStyles = size ? {
		control : (provided) => ({
			...provided,
			minHeight   : "2.142rem",
			height      : "2.142rem",
			borderColor : error && "#f44336 !important",
		}),

		valueContainer : (provided) => ({
			...provided,
			minHeight : "2.142rem",
			fontSize  : "0.857rem",
		}),

		input : (provided) => ({
			...provided,
			margin : "0px",
		}),
		indicatorsContainer : (provided) => ({
			...provided,
			height : "2.142rem",
		}),
		placeholder : (provided) => ({
			...provided,
			whiteSpace : "nowrap",
		}),
	} : {
		control : (provided) => ({
			...provided,
			borderColor : error && "#f44336 !important",
		}),
	};

	return customStyles;
};

export default reactSelectStyles;
