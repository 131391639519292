import PropTypes              from "prop-types";
import { Button }             from "reactstrap";
import { isValidElementType } from "react-is";
import { toast }              from "react-toastify";
import { X }                  from "react-feather";

const ToasterComponent = ({
	body,
	title,
	icon : Icon,
}) => (
	<div className="custom-toast">
		<div className="upper">
			<Icon size={16} />
			<span className="title">
				{title}
			</span>
			<Button.Ripple
				color='transparent'
				className='closeToaster'
			>
				<X size={16} />
			</Button.Ripple>
		</div>
		<div className="lower">
			<span className="text">
				{body}
			</span>
		</div>
	</div>
);

ToasterComponent.propTypes = {
	icon : (props, propName) => {
		if (props[propName] && !isValidElementType(props[propName])) {
			return new Error(
				"Invalid prop 'icon' supplied to 'ToasterComponent': the prop is not a valid React component"
			);
		}
	},
	title : PropTypes.string.isRequired,
	body  : PropTypes.string.isRequired,
};

/**
 * Custom implementation helper for displaying toasts
 *
 * @author Alcalá León Yael Mártin A. <yael.alcalla@gmail.com>
 */
const toaster = {
	info : props => toast.info(<ToasterComponent {...props} />, {
		position        : toast.POSITION.TOP_RIGHT,
		hideProgressBar : true,
		autoClose       : 1000000000000000,
		className       : "info",
	}),
};

export default toaster;
