const deepAccessObj = (name = "", obj) => {
	if (!obj) return null;

	const splitName = name.split(".");

	if (splitName.lenght < 1) return obj?.[name];

	let nestedVal = obj;

	splitName.forEach(key => {
		nestedVal = nestedVal?.[key];
	});

	return nestedVal;
};

export default deepAccessObj;
