import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	docs           : [],
	data           : {},
	filesToRemoved : [],
};

const FilesSlice = createSlice({
	name     : "files",
	initialState,
	reducers : {
		getAllFiles : (state, action) =>  {
			return {
				...state,
				...action.payload,
			};
		},
		setFileToRemove : (state, action) => {
			return {
				...state,
				filesToRemoved : [
					...state.filesToRemoved,
					action.payload,
				],
			};
		},
		setFilesToRemove : (state, action) => {
			return {
				...state,
				filesToRemoved : [
					...state.filesToRemoved,
					...action.payload,
				],
			};
		},
		filesRemoved : (state) => {
			return {
				...state,
				filesToRemoved : [],
			};
		},
		clearFiles : (state) => {
			return initialState;
		},
	},
});

export default FilesSlice;
