import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	docs          : [],
	data          : {},
	page          : 1,
	totalPages    : 1,
	totalDocs     : 0,
	pagingCounter : 0,
	limit         : 16,
};

const CreditgrantorsSlice = createSlice({
	name     : "credit",
	initialState,
	reducers : {
		getAllCredit : (state, action) =>  {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

export default CreditgrantorsSlice;
