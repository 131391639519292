import React, { useState, useEffect } from "react";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Mapa = ({
	apiKey = "AIzaSyDCl-xLN14fyZzefqi3kEcvcvvZ8VB6M3g",
	center,
	onMapClick,
}) => {

	const mapStyles = {
		height : "300px",
		width  : "100%",
	};

	const defaultCenter = { lat : 40.7128, lng : -74.0060 };
	const [mapCenter, setMapCenter] = useState((center && center.lat !== null && center.lng !== null) ? center : defaultCenter);

	const handleMapClick = (event) => {
		const clickedLat = event.latLng.lat();
		const clickedLng = event.latLng.lng();

		const newPoint = (clickedLat !== null && clickedLng !== null) ? { lat : clickedLat, lng : clickedLng } : defaultCenter;
		setMapCenter(newPoint);

		if (onMapClick) {
			onMapClick(newPoint);
		}
	};

	useEffect(() => {
		const newPoint = (center && center.lat !== null && center.lng !== null) ? center : defaultCenter;
		setMapCenter(newPoint);
	}, [center]);

	return (
		<LoadScript googleMapsApiKey={apiKey}>
			<GoogleMap mapContainerStyle={mapStyles} zoom={10} center={mapCenter} onClick={handleMapClick}>
				{mapCenter && <Marker position={mapCenter} />}
			</GoogleMap>
		</LoadScript>
	);
};

export default Mapa;
