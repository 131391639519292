import { useEffect }            from "react";
import { connect }              from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

const PrivateRoute = ({
	role,
	roles,
	loggedIn,
	component : Component,
	render    : RenderComponent,
	...rest
}) => {
	if (!loggedIn) return <Redirect to="/auth/login" />;

	const history = useHistory();

	const handleRedirect = () => {
		if (roles.includes(role)) return;
		history.push("/app/error/403");
	};

	useEffect(() => handleRedirect(), [role, roles]);

	return RenderComponent
		? <RenderComponent {...rest} />
		: <Component {...rest} />;
};

const mapStateToProps = ({ _authSlice }) => ({
	loggedIn : _authSlice?.loggedIn   ?? false,
	role     : _authSlice?.user?.role ?? "",
});

export default connect(mapStateToProps)(PrivateRoute);
