const getTime = (val, options = {}) => {
	if (!val) {
		return null;
	}
	const date = new Date(val);

	return date.toLocaleString("es-MX", {
		timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone,
		...options,
	});
};

export default getTime;
