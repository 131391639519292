// import "regenerator-runtime/runtime"; Uncomment this line if bugs appear.
import { combineReducers } from "redux";
import { configureStore }  from "@reduxjs/toolkit";
import { setupListeners }  from "@reduxjs/toolkit/query";
import localForage         from "localforage";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";

// Import Own Components
import { uncapitalize } from "Helpers";
import { api }          from "./api";
import * as Slices      from "./Slices";
import VuexyReducers    from "./vuexy/rootReducer";


const rootReducer = combineReducers({
	...VuexyReducers,
	...Object
		.entries(Slices)
		.reduce((acc, [key, value]) => ({
			...acc,
			["_" + uncapitalize(key)] : value.reducer,
		}), {}),

	[api.reducerPath] : api.reducer,
});

const persistConfig = {
	key       : "root",
	storage   : localForage,
	whitelist : ["_authSlice"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
	reducer    : persistedReducer,
	devTools   : process.env.NODE_ENV !== "production",
	middleware : getDefaultMiddleware => getDefaultMiddleware({
		serializableCheck : {
		  ignoredActions : [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	  })
		.concat(api.middleware),
});

setupListeners(Store.dispatch);

export const persistor = persistStore(Store);

export default Store;
