import { createSlice } from "@reduxjs/toolkit";

const modalList = {
	confirmationModal          : true,
	addPreferencesModal        : true,
	addLocalitiesModal         : true,
	addRequestLocalityModal    : true,
	returnStatus               : true,
	captureRequestModal        : true,
	selectPeritControllerModal : true,
	apprasialMenuModal         : true,
	uploadFileModal            : true,
	equivalenceTable           : true,
};

const propsByModal = {
	...Object.keys(modalList).reduce((acc, modalName) => ({
		...acc,
		[modalName] : {},
	}), {}),
};

const initialState = {
	props : propsByModal,
	...Object.keys(modalList).reduce((acc, modalName) => ({
		...acc,
		[modalName] : false,
	}), {}),
};

const ModalSlice = createSlice({
	name     : "modal",
	initialState,
	reducers : {
		openModal : (state, action) => {
			const modalKey =  action.payload.type;

			state[modalKey]       = true;
			state.props[modalKey] = action?.payload?.props ?? {};
		},
		closeModal : (state, action) => {
			const modalKey =  action.payload;

			state[modalKey]       = false;
			state.props[modalKey] = {};
		},

		closeAll : (state) => {
			return { ...initialState };
		},
	},
});

export default ModalSlice;
