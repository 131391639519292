import { AccessControl } from "accesscontrol";

const grantsObject = {
	administrator : {
		users : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
		request : {
			"create:any" : ["*"],
			"read:any"   : ["*"],
			"update:any" : ["*"],
			"delete:any" : ["*"],
		},
	},
	consult : {
		users : {
			"read:any"   : ["*"],
			"update:any" : [
				"*",
				"!prefix",
				"!name",
				"!lastname",
				"!maiden_name",
				"!rfc",
				"!username",
				"!role",
				"!password",
				"!career",
				"!specialty",
				"!specialty_certificate",
				"!identification_card",
				"!places",
				"!shf",
				"!type_shf",
				"!date_expiration",
				"!key_tdf",
				"!key_perit_independent",
				"!enable_controller_evaluator",
				"!enable_print_iavsa",
				"!enable_controller_evaluator_iavsa",
				"!disableUser",
			],
		},
		request : {
			"create:any" : ["*"],
			"read:any"   : ["*", "!controller", "!valuer"],
			"update:any" : ["*", "!status", "!controller", "!valuer"],
		},
	},
	guest : {
		users : {
			"read:any"   : ["*"],
			"update:any" : [
				"*",
				"!prefix",
				"!name",
				"!lastname",
				"!maiden_name",
				"!rfc",
				"!username",
				"!role",
				"!password",
				"!career",
				"!specialty",
				"!specialty_certificate",
				"!identification_card",
				"!places",
				"!shf",
				"!type_shf",
				"!date_expiration",
				"!key_tdf",
				"!key_perit_independent",
				"!enable_controller_evaluator",
				"!enable_print_iavsa",
				"!enable_controller_evaluator_iavsa",
				"!disableUser",
			],
		},
		request : {
			"create:any" : ["*"],
			"read:any"   : ["*", "!controller", "!valuer"],
			"update:any" : ["*", "!status", "!controller", "!valuer"],
		},
	},
	perit : {
		users : {
			"read:any"   : ["*"],
			"update:any" : [
				"*",
				"!prefix",
				"!name",
				"!lastname",
				"!maiden_name",
				"!rfc",
				"!username",
				"!role",
				"!password",
				"!career",
				"!specialty",
				"!specialty_certificate",
				"!identification_card",
				"!places",
				"!shf",
				"!type_shf",
				"!date_expiration",
				"!key_tdf",
				"!key_perit_independent",
				"!enable_controller_evaluator",
				"!enable_print_iavsa",
				"!enable_controller_evaluator_iavsa",
				"!disableUser",
			],
		},
		request : {
			"create:any" : ["*"],
			"read:any"   : ["*", "!controller", "!valuer"],
			"update:any" : ["*", "!status", "!controller", "!valuer"],
		},
	},
	administrator_technician : {
		users : {
			"read:any"   : ["*"],
			"update:any" : [
				"*",
				"!prefix",
				"!name",
				"!lastname",
				"!maiden_name",
				"!rfc",
				"!username",
				"!role",
				"!password",
				"!career",
				"!specialty",
				"!identification_card",
				"!specialty_certificate",
				"!places",
				"!disableUser",
			],
		},
		request : {
			"create:any" : ["*"],
			"read:any"   : ["*", "!controller", "!valuer"],
			"update:any" : ["*", "!status", "!controller", "!valuer"],
		},
	},
};

const ac = new AccessControl(grantsObject);

export default ac;
