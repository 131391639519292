import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	docs          : [],
	data          : {},
	page          : 1,
	totalPages    : 1,
	totalDocs     : 0,
	limit         : 16,
	pagingCounter : 0,
	formErros     : null,

	filters : {
		search  : "",
		order   : -1,
		limit   : 16,
		date    : undefined,
		orderBy : undefined,
	},
};

const RequestSlice = createSlice({
	name     : "request",
	initialState,
	reducers : {
		setRequests : (state, action) => ({
			...state,
			...action.payload,
		}),

		setFilters : (state, action) => {
			const payload   = { ...action.payload };
			const { clean } = payload;

			let newFilters;

			if (clean) {
				delete  payload["clean"];

				newFilters = { ...payload };
			} else {
				newFilters = Object.assign({}, state.filters, payload );
			}

			state.filters = newFilters;
		},

		clearFilters : (state) => void(state.filters = initialState.filters),

		setFormErrors : (state, action) => void(state.formErros = action.payload),

		clearFormErrors : (state) => void(state.formErros = initialState.formErros),
	},
});

export default RequestSlice;
