import Toaster from "Components/Global/Toaster";

const toastDispacher = {
	apprasialCRUD : {
		success : () => Toaster.success({
			title : "Éxito",
			body  : "El avalúo se creo o actualizó correctamente.",
		}),
		error : () => Toaster.error({
			title : "Ocurrio un error en el avalúo",
			body  : "No se puedo crear o editar el avalúo, favor de comunicarse con el administrador para resovel este problema.",
		}),
	},
	peritEdited : {
		success : () => Toaster.success({
			title : "Perito actualizado",
			body  : "Se actualizo el perito del avalúo.",
		}),
		error : () => Toaster.error({
			title : "Error al actualizar el perito",
			body  : "No se puedo actualizar el perito del avalúo.",
		}),
	},
	statusPetition : {
		success : () => Toaster.success({
			title : "Estado de solicitud actualizado",
			body  : "La solicitud se ha actualizado correctamente",
		}),

		error : () => Toaster.error({
			title : "Error",
			body  : "Ocurrió un error, favor de revisar su conexión a internet.",
		}),

		error409 : () => Toaster.error({
			title : "Error",
			body  : "El formulario tiene campos vacíos que son requeridos.",
		}),
		warning : () => Toaster.warning({
			title : "Advertencia",
			body  : "La solicitud no puede ser cambiada a estado de registrada, porque no existe el CUV o RUV.",
		}),
	},
	requestCRUD : {
		success : (isEditing) => Toaster.success({
			title : "Solicitud " + (isEditing ? "Editada" : "Creada"),
			body  : `La solictud se ha ${(isEditing ? "editado" : "creado")} exitosamente!`,
		}),
		error : () => Toaster.error({
			title : "Error",
			body  : "Ocurrió un error, favor de revisar su conexión a internet.",
		}),
	},
	filesCRUD : {
		success : () => Toaster.success({
			title : "Archivos Guardados",
			body  : "Los archivos se guardaron exitosamente",
		}),
		error : () => Toaster.error({
			title : "Error",
			body  : "Hubo un problema al cargar algun archivo, favor de revisar su conexión a internet o el tamaño máximo de los archivos.",
		}),
	},
	zipCode : {
		warning : (zipCode) => Toaster.warning({
			title : "CP no valido",
			body  : <p>El código postal <b>{zipCode}</b> no existe.</p>,
		}),
	},
};

export default toastDispacher;
