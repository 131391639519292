import { createAsyncThunk } from "@reduxjs/toolkit";

const createServiceSlice = asyncActions => {
	const result = {};

	for (const [actionName, fn] of Object.entries(asyncActions)) {
		result[actionName] = createAsyncThunk(actionName, fn);
	}

	return result;
};

export default createServiceSlice;
