import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	token    : "",
	loggedIn : false,
	user     : {
		email : "",
	},
};

const AuthSlice = createSlice({
	name     : "auth",
	initialState,
	reducers : {
		setUserData : (state, action) => {
			state.token    = action.payload.token;
			state.user     = action.payload.user;
			state.loggedIn = true;
		},
		setEmail      : (state, action) => void(state.user.email = action.payload.email),
		clearUserData : state => initialState,
	},
});

export default AuthSlice;
