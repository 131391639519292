// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";

export const reducers = {
	navbar,
	layout,
};

export default reducers;
