/* eslint-disable eqeqeq */
import moment from "moment";

/**
 * Gets the working days between to moment dates
 *
 * @param start
 * @param end
 * @returns
 */
const calculateWorkingDays = (start, end) => {
	const lastDay = moment(end);
	const firstDay = moment(start);
	let calcBusinessDays = 1 + (lastDay.diff(firstDay, "days") * 6 - (firstDay.day() - lastDay.day()) * 1 ) / 7;

	// if (lastDay.day() == 6) calcBusinessDays--;//SAT
	if (firstDay.day() == 0) calcBusinessDays--;//SUN

	return calcBusinessDays;
};
export default calculateWorkingDays;
