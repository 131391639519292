import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	docs          : [],
	data          : {},
	page          : 1,
	state         : undefined,
	municipality  : undefined,
	totalPages    : 1,
	totalDocs     : 0,
	pagingCounter : 0,
	limit         : 16,
};

const LocalitiesSlice = createSlice({
	name     : "locality",
	initialState,
	reducers : {
		getAllLocalities : (state, action) =>  {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

export default LocalitiesSlice;
